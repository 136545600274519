import { PencilSquareIcon, StarIcon, TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'

import IconButton from '@components/buttons/icon-button'
import { type OfferTableProps } from '@pages/booking-requests/entry/offers-table/offers-table.interfaces'
import { calculateDifferenceClass, convertCentsToEuros, displayDifference } from '@services/price'
import clsxm from '@services/tools/clsxm'

const OffersTable = ({ mainOffer, offers, onDeleteOffer, onEditOffer, toggleMain }: OfferTableProps) => {
  return (
    <table className='w-full whitespace-nowrap text-left text-sm leading-6'>
      <colgroup>
        <col className='w-full' />

        <col />

        <col />

        <col />

        <col />

        <col />
      </colgroup>

      <thead className='border-b border-gray-200 text-gray-900'>
        <tr>
          <th className='px-0 py-3 font-semibold' scope='col'>
            Offres
          </th>

          <th className='hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell' scope='col'>
            Tarif minimum
          </th>

          <th className='hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell' scope='col'>
            Tarif maximum
          </th>

          <th className='py-3 pl-8 pr-0 text-right font-semibold' scope='col'>
            Expedia
          </th>

          <th className='py-3 pl-8 pr-0 text-right font-semibold' scope='col'>
            Booking
          </th>

          <th className='py-3 pl-8 pr-0 text-right font-semibold' scope='col'>
            Actions
          </th>
        </tr>
      </thead>

      <tbody>
        {offers.map((offer) => (
          <tr className='border-b border-gray-100' key={offer.uid}>
            <td className='max-w-0 px-0 py-5 align-top'>
              <div className='truncate font-medium text-gray-900'>{offer.label}</div>
            </td>

            <td className='hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-primaryDark sm:table-cell'>
              {convertCentsToEuros(offer.minimumSellingPrice)}
            </td>

            <td className='hidden py-5 pl-8 pr-0 text-right align-top tabular-nums text-primaryDark sm:table-cell'>
              {convertCentsToEuros(offer.maximumSellingPrice)}
            </td>

            <td className='py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 sm:table-cell'>
              <div>{convertCentsToEuros(offer.externalPrices.expedia)}</div>

              {offer.externalPrices.expedia && offer.minimumSellingPrice && (
                <div
                  className={`${calculateDifferenceClass(offer.externalPrices.expedia, offer.minimumSellingPrice)} text-xs`}
                >
                  {`${displayDifference(offer.externalPrices.expedia, offer.minimumSellingPrice)}%`}
                </div>
              )}
            </td>

            <td className='py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700'>
              <div>{convertCentsToEuros(offer.externalPrices.booking)}</div>

              {offer.externalPrices.booking && offer.minimumSellingPrice && (
                <div
                  className={`${calculateDifferenceClass(offer.externalPrices.booking, offer.minimumSellingPrice)} text-xs`}
                >
                  {`${displayDifference(offer.externalPrices.booking, offer.minimumSellingPrice)}%`}
                </div>
              )}
            </td>

            <td className='py-5 pl-8 pr-0 text-right align-top tabular-nums text-gray-700 space-x-1'>
              <div className='flex space-x-1'>
                <IconButton
                  className={clsxm({
                    'bg-green-400 text-white border-transparent': mainOffer?.uid === offer.uid,
                    'text-gray-400': mainOffer?.uid !== offer.uid
                  })}
                  icon={StarIcon}
                  onClick={() => {
                    if (mainOffer?.uid !== offer.uid) {
                      toggleMain(offer['@id'])
                    }
                  }}
                />

                <IconButton
                  icon={PencilSquareIcon}
                  onClick={() => {
                    onEditOffer(offer)
                  }}
                />

                <IconButton
                  disabled={mainOffer?.uid === offer.uid}
                  icon={TrashIcon}
                  onClick={() => {
                    onDeleteOffer(offer.uid)
                  }}
                  style={'warning'}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default OffersTable
