import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'

import Button from '@components/buttons/button'
import { type AvailabilityItemInterfaces } from '@pages/booking-requests/entry/availabilities-list/availability-item'

const AvailabilityItem = ({ availability, onSelect }: AvailabilityItemInterfaces) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className='border rounded-lg overflow-hidden'>
      <button
        className='w-full font-bold bg-slate-100 p-4 flex justify-between items-center cursor-pointer'
        onClick={handleToggle}
        type='button'
      >
        {`${availability.room.description.en} - ${availability.room.code}`}

        {isExpanded
          ? (
            <ChevronUpIcon className='w-5 h-5' />
          )
          : (
            <ChevronDownIcon className='w-5 h-5' />
          )}
      </button>

      {isExpanded && (
        <ul className='divide-y divide-gray-100'>
          {[...new Map(availability.rates.map(rate => [rate.rateKey, rate])).values()].map((rate, index) => (
            <li
              className='flex items-center justify-between gap-x-6 px-4 py-5'
              key={`${rate.rateKey}-${index}`}
            >
              <div className='flex-1 min-w-0 w-3/5'>
                <p className='text-sm font-semibold leading-6 text-gray-900'>
                  {rate.boardName}
                </p>
              </div>

              <div className='w-1/5 text-right'>
                {`${rate.net} €`}
              </div>

              <div className='w-1/5 flex flex-none items-center justify-end'>
                <Button
                  icon={PlusIcon}
                  onClick={() => {
                    onSelect(rate, availability.room)
                  }}
                  size='small'
                  style='secondary'
                  type='button'
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default AvailabilityItem
