import {
  ArrowLeftStartOnRectangleIcon, CalendarDaysIcon,
  ChevronDownIcon, Cog6ToothIcon, HomeModernIcon,
  XCircleIcon
} from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import Guard from '@components/guard/guard'
import { type NavigationItem, type SidebarProps } from '@components/sidebar/sidebar.interfaces'
import { useLogout } from '@services/hooks/auth-token'
import clsxm from '@services/tools/clsxm'

const navigation: NavigationItem[] = [
  { href: '/booking-requests', icon: CalendarDaysIcon, name: 'booking-requests', title: 'sales' },
  { href: '/credit-packs', icon: CalendarDaysIcon, name: 'credit-packs' },
  { href: '/hotels', icon: HomeModernIcon, name: 'hotels', title: 'content' },
  { href: '/users', icon: Cog6ToothIcon, name: 'users', title: 'management' }
]

const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const { logout } = useLogout()
  const { t } = useTranslation('general', { keyPrefix: 'sidebar' })

  const closeSidebar = () => {
    setOpen(false)
  }

  return (
    <div className={clsxm(
      'bg-primaryDark px-2 py-6 pb-0 transition h-[100vh] tracking-wide text-sm font-medium',
      'fixed z-20 top-0 left-0 min-w-max sm:min-w-min w-3/5',
      'before:fixed before:-z-10 before:inset-0 before:bg-gray-900 before:opacity-50',
      open ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-1/3 pointer-events-none',
      'md:sticky md:w-56 md:before:hidden md:opacity-100 md:pointer-events-auto md:translate-x-0'
    )}
    >
      <div className='md:sticky h-full flex flex-col overflow-y-auto scrollbar-hide top-6'>
        <div className='flex items-center flex-shrink-0 px-4'>
          <NavLink to={'/'}>
            <img
              alt='logo'
              className='h-auto w-20'
              src='/assets/captainbooking.svg'
            />
          </NavLink>

          <button className='p-2 ml-auto md:hidden' onClick={closeSidebar}>
            <XCircleIcon className='w-8 h-8 text-white' />
          </button>
        </div>

        <nav className='mt-2 md:mt-6 flex-1'>
          {navigation.map((item) => (
            <Guard errorPage={false} key={item.name}>
              <>
                {item.title && (
                  <div className='flex items-center mt-4 mb-2'>
                    <item.icon
                      aria-hidden='true'
                      className='text-white flex-shrink-0 h-4 w-4'
                    />

                    <div className='uppercase text-sm text-gray-200 px-2'>{t(item.title)}</div>
                  </div>
                )}

                <NavLink
                  className={({ isActive }) =>
                    clsxm(
                      isActive ? 'bg-white text-primaryDark' : 'text-white hover:bg-white hover:text-primaryDark',
                      'group flex items-center px-2 py-1 text-sm rounded-md'
                    )
                  }
                  to={item.href}
                >
                  {t(item.name)}
                </NavLink>

                {item.sublinks?.map((link, index) => (
                  <NavLink
                    className={({ isActive }) =>
                      clsxm(
                        isActive ? 'bg-white text-primaryDark' : 'text-gray-200 hover:bg-white hover:text-primaryDark',
                        'group flex items-center px-4 py-1 my-1 text-sm rounded-md'
                      )
                    }
                    key={index}
                    to={link.href}
                  >
                    {({ isActive }) => (
                      <>
                        <ChevronDownIcon
                          aria-hidden='true'
                          className={clsxm(
                            'mr-3 flex-shrink-0 h-4 w-4 -rotate-90',
                            { 'fill-gray-400 group-hover:fill-primaryDark': !isActive, 'fill-primaryDark': isActive }
                          )}
                        />
                        {t(link.name)}
                      </>
                    )}
                  </NavLink>
                ))}
              </>
            </Guard>
          ))}
        </nav>

        <div className='flex flex-col py-4'>
          <button className='group flex items-center px-2 py-2 text-sm rounded-md text-gray-200 mt-4 md:mt-8 hover:bg-white hover:text-primaryDark w-full' onClick={logout}>
            <ArrowLeftStartOnRectangleIcon
              aria-hidden='true'
              className='gray-400 group-hover:gray-300 mr-3 flex-shrink-0 h-4 w-4'
            />

            {t('logout')}
          </button>

          <NavLink className='text-gray-200 text-xs text-center mt-4' to='/changelog'>
            {t('changelog')}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
