import { HomeModernIcon } from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const HotelsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'hotels' })
  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const onEntryClick = (hotel) => {
    navigate(`/hotels/${hotel.uid}`)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={HomeModernIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['code', 'name', 'destination', 'country']} name={'hotels'} onClick={onEntryClick} />
      </Guard>
    </Layout>
  )
}

export default HotelsList
