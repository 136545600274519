import { Form, Formik } from 'formik'
import * as React from 'react'
import * as Yup from 'yup'

import { Badge } from '@components/badge'
import Button from '@components/buttons/button'
import FormNumberField from '@components/form-fields/form-number-field'
import FormTextField from '@components/form-fields/form-text-field'
import { type OfferEditFormInterfaces } from '@pages/booking-requests/components/offer-edit-form/offer-edit-form.interfaces'

const OfferSchema = Yup.object().shape({
  externalPrices: Yup.object().shape({
    booking: Yup.number()
      .required('Le prix Booking est requis')
      .min(0.01, 'Le prix doit être supérieur à 0'),
    expedia: Yup.number()
      .required('Le prix Expedia est requis')
      .min(0.01, 'Le prix doit être supérieur à 0')
  }),
  label: Yup.string().required('Le label est requis')
})

const OfferEditForm = ({ offer, onSubmit }: OfferEditFormInterfaces) => {
  const initialValues = {
    externalPrices: {
      booking: offer.externalPrices.booking / 100,
      expedia: offer.externalPrices.expedia / 100
    },
    label: offer.label
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={OfferSchema}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        return (
          <Form className='flex h-full flex-col'>
            <div className='flex-1'>
              <div className='bg-gray-50 px-4 py-6 sm:px-6'>
                <div className='flex items-start justify-between space-x-3'>
                  <div className='space-y-1'>
                    <div className='text-base font-semibold leading-6 text-gray-900'>
                      {'Modifier une offre'}
                    </div>
                  </div>
                </div>
              </div>

              <div className='space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0'>
                <div className='space-y-2 px-4 py-5'>
                  <FormTextField label='Label' name='label' required />
                </div>

                <div className='px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4'>
                  <FormNumberField label='Prix Expedia' name='externalPrices.expedia' required zeroAllowed />

                  <FormNumberField label='Prix Booking' name='externalPrices.booking' required zeroAllowed />
                </div>

                <div className=''>
                  {offer.rooms.map((offerRoom, index) => (
                    <div className='my-10' key={index}>

                      <div className='flex items-center justify-between space-x-2 px-4 py-3 bg-slate-400' key={index}>
                        <div className='font-medium'>
                          {`Room ${index + 1}`}
                        </div>

                        <div className='space-x-2'>
                          {offerRoom.paxes.map((pax, paxIndex) => (
                            <Badge key={paxIndex}
                              text={`${pax.type} ${pax.age ? ` - ${pax.age} an${pax.age > 1 ? 's' : ''}` : ''}`}
                            />
                          ))}
                        </div>
                      </div>

                      {offerRoom.room && (
                        <div className='bg-slate-300 px-4 py-2'>
                          {offerRoom.room?.description.en}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className='flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6'>
              <div className='flex justify-end space-x-3'>
                <Button
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {'Modifier'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OfferEditForm
