import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import ButtonLoader from '@components/buttons/button-loader/button-loader'
import { type AvailabilitiesListProps } from '@pages/booking-requests/entry/availabilities-list/availabilities-list.interfaces'
import { AvailabilityItem } from '@pages/booking-requests/entry/availabilities-list/availability-item'
import useGetAvailabilities from '@services/api/resources/use-get-availabilities'

const AvailabilitiesList = ({ bookingRequest, hotel, onSelect, roomNumber, selectedRoom }: AvailabilitiesListProps) => {
  const {
    data: {
      'hydra:member': availabilities = []
    } = {},
    error,
    isLoading
  } = useGetAvailabilities({
    parameters: {
      bookingRequest,
      hotel,
      roomNumber
    }
  })

  if (isLoading) {
    return (
      <div className='mt-10 relative flex flex-col items-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center'>
        <ButtonLoader dark />

        <span className='mt-2 block text-sm text-gray-900'>
          Recherche de disponibilités
        </span>
      </div>
    )
  }

  if (error) {
    return (
      <div className='mt-10 relative block w-full rounded-lg border-2 border-dashed border-red-200 p-12 text-center'>
        <ExclamationCircleIcon className='mx-auto h-12 w-12 text-red-200' />

        <span className='mt-2 block text-sm text-gray-900'>
          Une erreur avec Hotelbeds est survenue
        </span>
      </div>
    )
  }

  const filteredAvailabilities = selectedRoom
    ? availabilities.filter((availability) => availability.room.code === selectedRoom.code)
    : availabilities

  if (filteredAvailabilities.length === 0) {
    return (
      <div className='mt-10 relative block w-full rounded-lg border-2 border-dashed border-red-200 p-12 text-center'>
        <ExclamationCircleIcon className='mx-auto h-12 w-12 text-red-200' />

        <span className='mt-2 block text-sm text-gray-900'>
          Aucune disponibilité pour cet hôtel sur ces dates
        </span>
      </div>
    )
  }

  return (
    <>
      {filteredAvailabilities.map((availability) => (
        <div className='border rounded-lg my-2' key={availability['@id']}>
          <AvailabilityItem availability={availability} onSelect={onSelect} />
        </div>
      ))}
    </>
  )
}

export default AvailabilitiesList
